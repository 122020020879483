<template>
  <div>
    <el-form class="el-form-custom mB20" :model="formData" :inline="true">
      <!-- <el-form-item label="监测类型">
        <el-select v-model="formData.typeGroupId" placeholder="请选择" @change="handleSelect">
          <el-option v-for="item in typeOptions" :key="item.id" :label="item.name" :value="item.id" />
        </el-select>
      </el-form-item>
      <el-form-item label="监测指标">
        <el-select v-model="formData.typeId" placeholder="请选择">
          <el-option v-for="item in targetOptions" :key="item.id" :label="item.name" :value="item.id" />
        </el-select>
      </el-form-item> -->
      <el-form-item label="测点编号：">
        <el-input v-model="formData.code" placeholder="请输入测点编号" />
      </el-form-item>
      <el-form-item>
        <el-button @click="handleSliver" :class="!myOption ? 'disable_icon' : ''">预警推送</el-button>
        <el-button @click="getData('query')">查询</el-button>
        <el-button @click="resetData">
          <svg class="icon reset-icon" aria-hidden="true">
            <use xlink:href="#iconlujing"></use>
          </svg>
          <span>重置</span>
        </el-button>
      </el-form-item>
    </el-form>
    <!-- stripe -->
    <el-table :data="tableData" style="width: 100%" :row-class-name="tableRowClassName">
      <el-table-column align="center" label="测点编号" prop="code" :min-width="headW"></el-table-column>
      <el-table-column align="center" label="监测类型" prop="typeGroupName"></el-table-column>
      <el-table-column align="center" label="监测指标" prop="typeName"></el-table-column>
      <el-table-column align="center" label="位置描述" prop="site"></el-table-column>
      <el-table-column align="center" label="告警时长" prop="duration">
        <template slot-scope="scope">
          {{ durationOptions[scope.row.duration] }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="告警区间" prop="warnInfo" min-width="150"></el-table-column>
      <el-table-column align="center" label="操作" :min-width="endW">
        <template slot-scope="scope">
          <span class="operate-txt pointer" :class="!myOption ? 'disable_icon' : ''" @click="setThreshold(scope.row.targetId)"> 设置阈值 </span>
        </template>
      </el-table-column>
    </el-table>
    <common-pagination :total="total" :currentPage="currentPage" @handleCurrentChange="handleCurrentChange" />

    <el-dialog title="预警推送" :visible.sync="sliverFlag" width="800px" :before-close="handleClose" custom-class="sliver-dia">
      <div class="my-sliver">
        <el-tabs v-model="type" @tab-click="getSliver">
          <el-tab-pane label="邮箱" name="1"></el-tab-pane>
          <el-tab-pane label="短信" name="2"></el-tab-pane>
        </el-tabs>
      </div>
      <!-- <div class="seliver">
        <span class="pointer mR20" @click="type = 1">邮箱</span>
        <span class="pointer active" @click="type = 2">短信</span>
        <span class="seliver-line"></span>
      </div> -->
      <!-- <el-table :row-class-name="tableRowClassName" :data="tableData" tooltip-effect="dark" style="width: 100%" v-if="type == 1">
        <el-table-column prop="account" label="邮箱"></el-table-column>
        <el-table-column prop="silverLevel" label="推送级别">
          <template slot-scope="scope">
            <span>sss</span>
            <span>qqq</span>
            <span>www</span>
          </template>
        </el-table-column>
      </el-table> -->
      <table class="border-table-seliver" border="1" style="border-collapse: collapse; width: 100%; color: #fff">
        <tr>
          <th style="width: 300px">{{ type == 1 ? "邮箱" : "手机号" }}</th>
          <th>推送级别</th>
          <th style="width: 90px">操作</th>
        </tr>
        <tr v-for="(itm, idx) in sileverList" :key="idx">
          <!-- 部位 -->
          <td>
            <el-input v-model="itm.account" :placeholder="type == 1 ? '请输入邮箱' : '请输入手机号'"></el-input>
          </td>
          <!-- 系统 -->
          <td>
            <el-checkbox v-model="itm.level3" :true-label="1" :false-label="0">重度预警</el-checkbox>
            <el-checkbox v-model="itm.level2" :true-label="1" :false-label="0">中度预警</el-checkbox>
            <el-checkbox v-model="itm.level1" :true-label="1" :false-label="0">轻度预警</el-checkbox>
          </td>
          <!-- 构件/d指标 -->
          <td class="seliver-del pointer" @click="seliverDel(itm, idx)">删除</td>
        </tr>
      </table>
      <div style="text-align: center" class="seliver-bottom">
        <span class="sureBtn back-btn pointer font14 mR20" @click="handleClose">取消</span>
        <span class="backBtn submit-btn pointer mR20 font14" @click="silverSave()">保存</span>
        <span class="sureBtn add-btn back-btn pointer font14" @click="seliverAdd">添加</span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import CommonPagination from "@/components/Pagination";
export default {
  components: {
    CommonPagination
  },
  data() {
    return {
      level: 1,
      type: "1",
      sileverList: [],
      headW: "50px",
      endW: "30px",
      sliverFlag: false,
      total: 0,
      currentPage: 1,
      disabled: false,
      typeOptions: [],
      targetOptions: [],
      tableData: [],
      formData: { code: "" },
      durationOptions: ["不预警", "预警"]
    };
  },
  mounted() {
    // 官网体验版isAdmin不为1时禁用，正式版为1.isTrue是true时，不禁用。
    // if(sessionStorage.getItem('isAdmin') == 1) {
    // this.disabled = sessionStorage.getItem("isTrue") == "true" ? false : true;
    this.projectId = sessionStorage.getItem("projectId");
    // } else {
    // this.disabled = true
    // }
    this.getPointTypes();
    this.currentPage = Number(sessionStorage.getItem("warningCurrentPage")) || 1;
    console.log("查看返回是否走这里wwwwwwwwwww", this.currentPage);

    this.getData();
  },
  methods: {
    handleSliver() {
      if (!this.myOption) {
        this.$message.error("您没有权限！");
        return;
      }
      this.sliverFlag = true;
      this.getSliver();
    },
    seliverDel(itm, idx) {
      console.log("itm", itm, "idx", idx);
      this.sileverList.splice(idx, 1);
    },
    seliverAdd() {
      let obj = {
        account: "",
        level1: 0,
        level2: 0,
        level3: 0
      };
      this.sileverList.push(obj);
      console.log("silverlist", this.sileverList);
      this.$forceUpdate();
    },
    silverSave() {
      console.log("this.sileverList", this.sileverList);
      let n = this.sileverList.length,
        arr = [];
      for (let i = 0; i < n; i++) {
        if (this.sileverList[i].account) {
          this.sileverList[i].type = this.type;
          arr.push(this.sileverList[i]);
        }
      }
      this.$axios.post(`${this.baseURL}base/basewarn/save`, { baseWarnInfoList: arr, type: this.type, projectId: this.projectId }).then((res) => {
        res = res.data;
        if (res.errCode == 200) {
          this.sliverFlag = false;
        }
      });
    },
    getSliver() {
      this.$axios.get(`${this.baseURL}base/basewarn/get/${this.projectId}/${this.type}`).then((res) => {
        console.log("sliver", res.data);
        res = res.data;
        if (res.errCode == 200) {
          this.sileverList = res.data;
          this.sileverList = this.sileverList ? this.sileverList : [];
          if (!this.sileverList.length) {
            this.sileverList = [];
            for (let i = 0; i < 2; i++) {
              let obj = {
                account: "",
                level1: 0,
                level2: 0,
                level3: 0
              };
              this.sileverList.push(obj);
            }
            return;
          }
          if (this.sileverList.length == 1) {
            let obj = {
              account: "",
              level1: 0,
              level2: 0,
              level3: 0
            };
            this.sileverList.push(obj);
          }
        }
      });
    },
    handleClose() {
      this.sliverFlag = false;
    },
    tableRowClassName({ row, rowIndex }) {
      //条纹变色
      if (rowIndex % 2 == 1) {
        return "hui1";
      } else {
        return "lv1";
      }
    },

    handleSelect(val) {
      this.getTargetOptions();
    },
    resetData() {
      this.formData = { code: "" };
      this.handleCurrentChange(1);
      // this.getData();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      console.log("val", val);
      sessionStorage.setItem("warningCurrentPage", val);
      this.getData();
    },
    setThreshold(id) {
      if (!this.myOption) {
        this.$message.error("您没有查看权限！");
        return;
      }
      console.log("id", id);
      if (this.disabled) return;
      this.$router.push("/SetDynamicDetail/" + id);
    },
    getPointTypes() {
      this.$axios.get(`${this.baseURL}base/baseTargetTypeGroup/`).then((res) => {
        res = res.data;
        if (res.errCode == 200) {
          res.data.unshift({ id: "", name: "全部" });
          this.typeOptions = res.data;
          this.getTargetOptions();
        }
      });
    },
    getTargetOptions() {
      this.$axios
        .get(`${this.baseURL}base/baseTargetType/byGroupId`, {
          typeGroupId: this.formData.typeGroupId
        })
        .then((res) => {
          res = res.data;
          if (res.errCode == 200) {
            res.data.unshift({ id: "", name: "全部" });
            this.targetOptions = res.data;
          }
        });
    },
    getData(val) {
      if (val) {
        this.currentPage = 1;
        sessionStorage.setItem("warningCurrentPage", 1);
      }
      this.$axios
        .get(`${this.baseURL}setttings/settingsThresholdDynamic/${this.currentPage}/10/${this.projectId}`, {
          params: this.formData
        })
        .then((res) => {
          res = res.data;
          console.log("res", res);
          if (res.errCode == 200) {
            this.total = res.data.total;
            this.tableData = res.data.records;
            // let arr = [];
            // res.data.records.forEach((e) => {
            //   e["target"] = { duration: "", interval: "" };
            //   e.target.duration = e.duration;
            //   // 告警区间（正向(0.5,0.7,0.8)，负向(0.5,0.6,0.7)）
            //   e.target.interval = e.clower ? `正向(${e.clower},${e.blower},${e.alower}),负向(${e.dupper},${e.eupper},${e.fupper})` : "-";
            //   arr.push(e.target);
            // });

            // console.log("tabledata", arr);

            // this.tableData = arr;
          }
        });
    }
  }
  // beforeDestroy() {
  //   sessionStorage.setItem("warningCurrentPage", 1);
  // }
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
  padding-top: 15px;
}

.my-sliver ::v-deep .el-tabs__item.is-active,
.my-sliver ::v-deep .el-tabs__item {
  color: #fff;
  width: 90px;
  text-align: center;
  padding: 0;
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
}

.my-sliver {
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  margin-bottom: 31px;
}

.my-sliver ::v-deep .el-dialog__header .el-dialog__title {
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
}

.my-sliver ::v-deep .el-tabs__header {
  margin-bottom: 3px;
}

.my-sliver ::v-deep .el-tabs__nav-wrap::after {
  background-color: rgba(17, 102, 255, 1);
}

.my-sliver ::v-deep .el-tabs__active-bar {
  // height: 5px;
  border-bottom: 5px solid;
  border-image: linear-gradient(119deg, rgba(17, 102, 255, 1), rgba(0, 50, 175, 0.95)) 1 1;
}

.seliver-del {
  color: #32c5ff;
}

.seliver {
  // padding-bottom: 15px;
  margin-bottom: 20px;
  display: flex;
  height: 32px;

  span {
    display: inline-block;
    height: 100%;
    padding: 0 32px;
    color: #fff;
  }

  .active {
    border-bottom: 5px solid;
    border-image: linear-gradient(119deg, rgba(17, 102, 255, 1), rgba(0, 50, 175, 0.95)) 1 1;
  }

  .unactive {
    border-bottom: 1px solid #2772f0;
  }

  .seliver-line {
    flex: 1;
    height: 100%;
    border-bottom: 1px solid #2772f0;
  }
}

::v-deep .el-dialog.sliver-dia {
  width: 800px !important;

  .el-radio__label {
    color: #fff;
  }

  .el-input__inner {
    color: #fff;
    border: none;
    background-color: transparent !important;
    font-size: 16px !important;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
  }
}

.add-btn {
  background-color: #1658de;
}

.font14 {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  padding: 6px 32px;
}

.seliver-bottom {
  text-align: center;
  margin-top: 62px;
  margin-bottom: 33px;
}

.border-table-seliver {
  border-collapse: collapse;
  border: none;
  margin-bottom: 20px;

  tr {
    width: 100%;
    height: 40px;
  }
}

.border-table-seliver td {
  padding-left: 10px;
  border: 1px solid #2772f0;
  text-align: center;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
}

.border-table-seliver th {
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  height: 40px;
  font-size: 16px;
  padding-left: 10px;
  border: 1px solid #2772f0;
}

.el-table {
  margin-bottom: 35px;
}

::v-deep .hui1 {
  background: #091d46 !important;
}

::v-deep .lv1 {
  background: #0c265a !important;
}
</style>
